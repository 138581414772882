import moment from "moment";

export function subtractOneWorkingDayIndia(date) {
    // Parse the date into a moment object
    let currentDate = moment(date);

    // Subtract one day
    currentDate.subtract(1, "days");

    // If the resulting day is Sunday (0), keep subtracting until it's a working day
    while (currentDate.day() === 0) {
        currentDate.subtract(1, "days");
    }

    return currentDate.format("YYYY-MM-DD");
}
