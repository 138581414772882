import React from "react";
import GlegooBold from "../../assets/fonts/Glegoo/Glegoo-Bold.ttf";
import GlegooRegular from "../../assets/fonts/Glegoo/Glegoo-Regular.ttf";
import GilroyBold from "../../assets/fonts/gilroy/Gilroy-Bold.ttf";
import GilroyMedium from "../../assets/fonts/gilroy/Gilroy-Medium.ttf";
import GilroyRegular from "../../assets/fonts/gilroy/Gilroy-Regular.ttf";
import GilroySemiBold from "../../assets/fonts/gilroy/Gilroy-SemiBold.ttf";

import {
  Document,
  Page,
  View,
  Text,
  Image,
  Font,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import bharat from "../../assets/NewImage/bharat.png";
import bharatBlack from "../../assets/NewImage/bharatBlack.png";
import bharatandilca from "../../assets/NewImage/bharatandilca.png";
import logo from "../../assets/NewImage/logoR.png";
import logoLLP from "../../assets/NewImage/logoLLP.png";
import qrcode from "../../assets/NewImage/qrcode.png";
import sign2 from "../../assets/NewImage/sign2.png";
import sign3 from "../../assets/NewImage/sign3.png";
import bgImage from "../../assets/NewImage/bgImage.png";
import leftArrow from "../../assets/images/leftArrow.png";
import rightArrow from "../../assets/images/rightArrow.png";
// import sigen1 from "../../assets/images/sigen1.png";
import Footer from "../footer/Footer";
import moment from "moment";

Font.register({
  family: "Glegoo-Regular",
  src: GlegooRegular,
});

Font.register({
  family: "Glegoo-Bold",
  src: GlegooBold,
});
Font.register({
  family: "Gilroy-Regular",
  src: GilroyRegular,
});
Font.register({
  family: "Gilroy-Medium",
  src: GilroyMedium,
});
Font.register({
  family: "Gilroy-Bold",
  src: GilroyBold,
});
Font.register({
  family: "Gilroy-SemiBold",
  src: GilroySemiBold,
});

const styles = StyleSheet.create({
  backgroundImage: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    height: "380.882px",
    width: "389px",
  },
  mainTableLayout: {
    display: "flex",
    flexDirection: "row",
    fontSize: "8px",
  },
  tableCol: {
    width: "16.66%",
    textAlign: "center",
    borderRightWidth: 0.5,
    borderColor: "#050340",
  },
  tableLastCol: {
    width: "16.66%",
    textAlign: "center",
  },
  subCol: {
    padding: "6 0",
    borderBottomWidth: 0.5,
    borderColor: "#050340",

  },
  LastSubCol: {
    padding: "6 0",
  },
  lastbox: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  height: {
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  margin: {
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  tableLastColWidth: {
    width: "100%"
  },
  forVerticalCenter: {
    flex: 1,
    justifyContent: "center",
    alignContent: "center"
  },
});
const CertificateGradeIS17 = (props) => {
  const propsData = props.propsData;
  const createdAtDate = moment(propsData && propsData.created_at).format("YYYY-MM-DD");

  var visibleIlacLogo = false
  var visibleILLPLogo = false

  if (moment(createdAtDate).isSameOrAfter("01-01-2025")) {
    visibleIlacLogo = true;
  }
  if (moment(createdAtDate).isSameOrAfter("2025-04-01")) {
    visibleILLPLogo = true;
  }

  return (
    <>
      <View
        style={{
          border: "1px double #050340",
          margin: "10px",
        }}
      >
        <View
          style={{
            border: "1px double #050340",
            margin: "4px",
            padding: "10px 0 0 0",
            height: "100%",
          }}
        >
          <View style={styles.backgroundImage}>
            <Image src={bgImage} style={styles.backgroundImage} />
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 15px",
            }}
          >
            <View
              style={{
                width: "235",
                height: "95px",
              }}
            >
              {
                visibleILLPLogo ? <Image src={logoLLP} alt="logo" /> :
                  <>
                    <Image src={logo} alt="logo" />
                    <Text
                      style={{
                        color: "#2B2A28",
                        textAlign: "right",
                        fontFamily: "Gilroy-Medium",
                        fontSize: "17px",
                        paddingTop: '4px'
                      }}
                    >
                      (Laboratory Division)
                    </Text>
                  </>
              }
            </View>
            <View
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }} >
              {/* {shouldHideView ? null : (
                <> */}
              {
                visibleIlacLogo ?
                  <Image
                    src={bharatandilca}
                    alt="bharat"
                    style={{
                      width: "160px",
                      height: "87px",
                    }}
                  /> : <>
                    <Image
                      src={bharatBlack}
                      alt="bharat"
                      style={{
                        width: "66px",
                        height: "75px",
                      }}
                    />
                    <Text
                      style={{
                        fontSize: "8px",
                        textAlign: "center",
                        marginTop: "5px",
                        fontFamily: "Gilroy-Bold",
                        color: "#2B2A28",
                      }}
                    >
                      Certi. No : TC -  11481
                    </Text>
                  </>
              }
              {/* </>
              )} */}
            </View>
          </View>
          <View
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              color: "#5200C6",
              marginTop: "20px",
              padding: "0px 15px",
            }}
          >
            <View style={{ textAlign: "right", width: "60%" }}>
              <Text
                style={{
                  fontSize: "24px",
                  fontFamily: "Glegoo-Bold",
                }}
              >
                Test Report
              </Text>
            </View>
            <View style={{ width: "30%", textAlign: "right" }}>
              <Text
                style={{
                  color: "#5200C6",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "12px",
                }}
              >
                IS 17955:2023
              </Text>
              <Text
                style={{
                  color: "#5200C6",
                  fontFamily: "Gilroy-Bold",
                  fontSize: "12px",
                }}
              >
                (Filtermedia Sand)
              </Text>
            </View>
          </View>
          <View
            style={{
              borderTop: "1px solid #5200C6",
              margin: "0px 15px",
            }}
          ></View>
          <View
            style={{
              width: "26%",
              border: "1.3px solid #5200C6",
              backgroundColor: "#5200C6",
              margin: "-1px 15px 0px auto",
            }}
          ></View>
          <View
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "row",
              marginTop: "20px",
              fontSize: "8px",
              color: "#2B2A28",
              padding: "0px 15px",
            }}
          >
            <View style={{ width: "100%" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  ULR No. :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  {propsData && propsData.ulr_number && propsData.ulr_number}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Name of Customer :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                    marginLeft: "2.5px",
                    width: "180px"
                  }}
                >
                  Pinal Corporation {propsData.tested_for ? `(${propsData.tested_for})` : ""}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Contact Information of Customer :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  inquiry@pinalsand.com
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Sample ID :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  {propsData && propsData.sample_id && propsData.sample_id || "-"}
                </Text>
              </View>
              {/* <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Batch No. :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  B{propsData && propsData.batch_count && propsData.batch_count}
                </Text>
              </View> */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Sample Req. Slip No. :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  {/* {propsData &&
                    propsData.sample_request_slip_number &&
                    propsData.sample_request_slip_number} */}
                  {propsData && propsData.sample_id && propsData.sample_id || "-"}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Sample Weight :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  2kg
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Condition of Sample :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  Good and Moisture Free
                </Text>
              </View>
            </View>
            <View style={{ width: "100%" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Date of Report Issued :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  {propsData &&
                    propsData.date_of_report_issued &&
                    moment(propsData.date_of_report_issued).format(
                      "DD MMM YYYY"
                    )}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Test Report No. :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  {/* {propsData &&
                    propsData.test_report_number &&
                    propsData.test_report_number || "-"} */}
                  {propsData && propsData.sample_id && propsData.sample_id || "-"}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Lab Temp :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  26 °C 70% rh
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Sample Received Date :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  {propsData &&
                    propsData.date_of_testing &&
                    moment(propsData.date_of_testing).format(
                      "DD MMM YYYY"
                    )}
                </Text>
              </View>
              {/* <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Material Type :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  Quartzite
                </Text>
              </View> */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Sample Detail :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  Quartz Powder
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2px",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Gilroy-Medium",
                  }}
                >
                  Date of Testing :
                </Text>
                <Text
                  style={{
                    fontFamily: "Gilroy-Bold",
                    marginLeft: "2.5px",
                  }}
                >
                  {propsData &&
                    propsData.date_of_testing &&
                    moment(propsData.date_of_testing).format("DD MMM YYYY")}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              border: "0.5px solid #050340",
              marginTop: 10,
              marginLeft: "15px",
              marginRight: "15px",
            }}
          >
            <Text
              style={{
                fontSize: "10px",
                textAlign: "center",
                padding: "6 0 4 0",
                fontFamily: "Gilroy-Bold",
              }}
            >
              Test Result
            </Text>
            <Text
              style={{
                fontSize: "8px",
                textAlign: "start",
                padding: "8 0 4 6",
                fontFamily: "Gilroy-Bold",
                borderTop: "0.5px solid #050340",
                borderBottom: "0.5px solid #050340",
              }}
            >
              1. Physical Parameter
            </Text>
            <View style={styles.mainTableLayout}>
              <View style={styles.tableCol}>
                <View style={[styles.subCol, styles.height]}>
                  <Text>Parameter</Text>
                </View>
                <View style={styles.subCol}>
                  <Text>Acceptance</Text>
                </View>
                <View style={styles.subCol}>
                  <Text>Test Result</Text>
                </View>
                <View style={[styles.LastSubCol, styles.height, styles.margin]}>
                  <Text>Test Method</Text>
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={[styles.subCol, styles.height]}>
                  <Text>Effective size</Text>
                </View>
                <View style={styles.subCol}>
                  <Text>0.45 - 0.70</Text>
                </View>
                <View style={styles.subCol}>
                  <Text>{propsData && propsData.effective_size && propsData.effective_size}</Text>
                </View>
                <View style={[styles.LastSubCol, styles.height, styles.margin]}>
                  <Text>PC/SOP/QCD/12</Text>
                  <Text style={{ fontSize: "7.5px" }}>(Issue 1, Rev. 0, Dtd.:01/12/2023)</Text>
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={[styles.subCol, styles.height]}>
                  <Text>Uniformity </Text>
                  <Text> coefficient (uc)</Text>
                </View>
                <View style={styles.subCol}>
                  <Text>1.3 - 1.7</Text>
                </View>
                <View style={styles.subCol}>
                  <Text>{propsData && propsData.uc && propsData.uc}</Text>
                </View>
                <View style={[styles.LastSubCol, styles.height, styles.margin]}>
                  <Text>PC/SOP/QCD/12</Text>
                  <Text style={{ fontSize: "7.5px" }}>(Issue 1, Rev. 0, Dtd.:01/12/2023)</Text>
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={[styles.subCol, styles.height]}>
                  <Text>Specific Gravity</Text>
                </View>
                <View style={styles.subCol}>
                  <Text>{"> 2.50"}</Text>
                </View>
                <View style={styles.subCol}>
                  <Text>{propsData && propsData.specific_gratuity && propsData.specific_gratuity}</Text>
                </View>
                <View style={[styles.LastSubCol, styles.height, styles.margin]}>
                  <Text>IS 2386 (Part III) : 2018</Text>
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={[styles.subCol, styles.height]}>
                  <Text>Loss on ignition</Text>
                </View>
                <View style={styles.subCol}>
                  <Text>{"< 0.7"}</Text>
                </View>
                <View style={styles.subCol}>
                  <Text>{propsData && propsData.loss_on_ignition && propsData.loss_on_ignition}</Text>
                </View>
                <View style={[styles.LastSubCol, styles.height, styles.margin]}>
                  <Text>IS 17955:2023</Text>
                </View>
              </View>
              <View style={styles.tableLastCol}>
                <View style={styles.subCol}>
                  <Text>Impurities such as clay,loam,silt etc..</Text>
                </View>
                <View style={styles.subCol}>
                  <Text>{"< 5%"}</Text>
                </View>
                <View style={styles.subCol}>
                  <Text>{propsData && propsData.impurities && propsData.impurities}%</Text>
                </View>
                <View style={[styles.lastbox, styles.height, styles.margin]}>
                  <Text>IS 17955:2023</Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              border: "0.5px solid #050340",
              marginTop: 10,
              marginLeft: "15px",
              marginRight: "15px",
            }}
          >
            <Text
              style={{
                fontSize: "8px",
                textAlign: "start",
                padding: "8 0 4 6",
                fontFamily: "Gilroy-Bold",
                borderBottom: "0.5px solid #050340",
              }}
            >
              2. Chemical Parameter
            </Text>
            <View style={styles.mainTableLayout}>
              <View style={styles.tableCol}>
                <View style={styles.subCol}>
                  <Text>Parameter</Text>
                </View>
                <View style={styles.subCol}>
                  <Text>Acceptance</Text>
                </View>
                <View style={styles.subCol}>
                  <Text>Test Result</Text>
                </View>
                <View style={styles.LastSubCol}>
                  <Text>Test Method</Text>
                </View>
              </View>
              <View style={styles.tableLastColWidth}>
                <View style={styles.subCol}>
                  <Text style={{ paddingLeft: "6px" }}>Acid Solubility</Text>
                </View>
                <View style={styles.subCol}>
                  <Text style={{ paddingLeft: "6px" }}>{"< 5%"}</Text>
                </View>
                <View style={styles.subCol}>
                  <Text style={{ paddingLeft: "6px" }}>{propsData && propsData.acid_solubility && propsData.acid_solubility}%</Text>
                </View>
                <View style={styles.forVerticalCenter}>
                  <Text style={{ paddingLeft: "6px" }}>IS 17955:2023</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ marginTop: "10px" }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100px",
                margin: "auto",
              }}
            >
              <View>
                <Image src={leftArrow} style={{ width: "12px" }} />
              </View>
              <Text
                style={{
                  fontFamily: "Gilroy-Bold",
                  fontSize: "8px",
                  textAlign: "center",
                  marginTop: "3px",
                }}
              >
                End of the Report
              </Text>
              <View>
                <Image src={rightArrow} style={{ width: "12px" }} />
              </View>
            </View>
          </View>
          <View
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              marginTop: "3px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View>
              <View
                style={{
                  height: "60px",
                  width: "134px",
                  //border: "1px solid #E9E9ED",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image src={sign2} style={{ width: "87px", height: "39px" }} />
              </View>
              <View
                style={{
                  width: "60%",
                  margin: "0 auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    marginTop: "5px",
                    fontSize: "8px",
                    color: "#2B2A28",
                    fontFamily: "Gilroy-SemiBold",
                  }}
                >
                  Reviewed By
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: "#2B2A28",
                    fontFamily: "Gilroy-SemiBold",
                  }}
                >
                  {" "}
                  Barach Siddharthsinh
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: "#2B2A28",
                    fontFamily: "Gilroy-SemiBold",
                  }}
                >
                  {" "}
                  [Quality Manager]
                </Text>
              </View>
            </View>
            <View>
              <View
                style={{
                  height: "60px",
                  width: "134px",
                  //border: "1px solid #E9E9ED",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Image src={sign3} style={{ width: "79px", height: "33px" }} />
              </View>
              <View
                style={{
                  width: "60%",
                  margin: "0 auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    marginTop: "5px",
                    fontSize: "8px",
                    color: "#2B2A28",
                    fontFamily: "Gilroy-SemiBold",
                  }}
                >
                  Authorized Signatory
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: "#2B2A28",
                    fontFamily: "Gilroy-SemiBold",
                  }}
                >
                  {" "}
                  Pinal Patel
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: "#2B2A28",
                    fontFamily: "Gilroy-SemiBold",
                  }}
                >
                  {" "}
                  [CEO]
                </Text>
              </View>
            </View>
          </View>
          {/* <View
            style={{
              color: "#2B2A28",
              fontSize: "8px",
              marginTop: "13px",
              padding: "0px 15px",
            }}
          >
            <Text
              style={{
                fontFamily: "Gilroy-Regular",
              }}
            >
              * Remarks :
            </Text>
            <View
              style={{
                fontFamily: "Gilroy-Bold",
                padding: "4px 0px 0px 5px",
                display: "flex",
                rowGap: "3px",
              }}
            >
              <Text>• Test Sieves Confirming to ASTM E -11 : 2022.</Text>
              <Text>
                • Testing in according to ASTM D1556/D1556M - 15E1 in all
                respect.
              </Text>
            </View>
          </View> */}
          <View
            style={{
              position: "absolute",
              bottom: "50px",
              left: 0,
              right: 0,
              margin: "8px 15px",
              fontSize: "7px",
              fontFamily: "Gilroy-Medium",
              color: "black",
              lineHeight: "1.2px",
            }}
          >
            <Text>Note :</Text>
            <Text>
              1. The test report and result relate to the particular specimen/sample(s) of the material as delivered/received, at the time of testing in the laboratory.
            </Text>
            <Text>
              2. The sampling is not carried by Pinal Corporation (laboratory division), testing as per sample provided by the customer.
            </Text>
            <Text>
              3. This report may not be reproduced in part, without the permission of the laboratory.
            </Text>
            <Text>4. Any correction invalidate this Test Report.</Text>
            <Text>5. Test Sieves Confirming to IS 460 Part-1 - 2020</Text>
          </View>
          <Footer />
        </View>
      </View>
    </>

  )
}

export default CertificateGradeIS17